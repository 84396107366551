import styled from 'styled-components';
import mainLogo from './images/graphwarII_logo.png';
import play from './images/play_badge.png';
import apple from './images/app_store_badge.svg';
import steam from './images/steambadge.webp';
import itch from './images/itch_badge.svg';
import discord from './images/discord_mark_white.svg';

function App() {
  return (
    <MainPage>
    	<Content>
    		<Header>				
				<Graphwar src={mainLogo} alt="Graphwar logo" />		
			</Header>		

			<Introdution>	
				<P>
				Graphwar II is an artillery game in which you must hit your enemies using mathematical functions. 
					The trajectory of your shot is determined by the function you wrote, and your goal is to avoid the obstacles and your teammates and hit your enemies. The game takes place in a Cartesian Plane.
					Graphwar II brings a modern UI, new game modes, character customization, replays, and a campaign to be played in single player mode. Graphwar II brings both an easier introduction to new players and also newer challenges to veteran players. 
				</P>
			</Introdution>

			<Video>
				<Iframe 
					src="https://www.youtube.com/embed/We4mI_a1WhI" 
					title="YouTube video player" 
					frameborder="0" 
					allow="accelerometer; 
					autoplay; 
					clipboard-write; 
					encrypted-media; 
					gyroscope; 
					picture-in-picture; 
					web-share" allowfullscreen>.
				</Iframe>
			</Video>

			<Stores>
				<Badges>
					<Link href="https://play.google.com/store/apps/details?id=com.graphwar.graphwar_ii">
						<Image src={play}/>
					</Link>
		
					<Link href="https://apps.apple.com/app/graphwar-ii/id6517357575">
						<Image src={apple}/>
					</Link>
	
					<Link href="https://store.steampowered.com/app/3066840/Graphwar_II/">
						<Image src={steam}/>
					</Link>

					<Link href="https://graphwar.itch.io/graphwar-ii">
						<Image src={itch}/>
					</Link>
				</Badges>
			</Stores>

			<Communities>
				<Link href="https://discord.gg/Fmwq7XmCH2">
					<img src={discord}/>
				</Link>
			</Communities>
						

			<Contact>
				If you have questions or suggestions about Graphwar or Catacorp contact the following email: contact@graphwar.com
			</Contact>
			
			<Legacy>
				Graphwar 1 page is still available&nbsp;<A href="graphwar_1/index.html">here</A>.
			</Legacy>
	
		</Content>
    </MainPage>
  );
}

const MainPage = styled.div`
	width:100%;
	max-height: 1200px;
	margin: 0 auto;
	display: flex;
	background-color:#0f281d;
	font-family:Arial, Helvetica, sans-serif;
	color: white;
	font-size: 20px;
  	justify-content: center;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
	width: 70%;
	background-color:#1a3b2d;
`;

const Header = styled.div`
	display: flex;
	justify-content: center;
	height: 20%;
`;

const Graphwar = styled.img`
	max-width: 100%;
	height: auto;
	display: block;
	margin: 0 auto;
`;

const Introdution = styled.div`
	text-align: center;
	@media (max-width: 460px) {
    	flex-direction: column;
		height: auto;
		overflow-x: hidden;
		overflow-y: auto;
  	}
`;

const Video = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 20px;
`;

const Iframe = styled.iframe`
	width: 800px; 
	height: 420px;
`;

const Stores = styled.div`
	width: 100%;
	height: 60px;
	display:flex; 
	align-items: center;
	flex-direction: row;
	justify-content: center;
	margin-top: 20px;
	flex-wrap: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
	gap: 10px;
	@media (max-width: 460px) {
		display:flex; 
    	flex-direction: column;
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
  	}
`;

const Communities = styled.div`
	margin-top: 20px;
`;

const Badges = styled.div`
	display:flex;
	justify-content: center;
	width: 800px;
	height: 100%;
	@media (max-width: 460px) {
		display:flex; 
    	flex-direction: column;
		height: auto;
		width: auto;
  	}
`;

const Image = styled.img`
	width: auto;
	height: 100%;
	flex-shrink: 0;
	@media (max-width: 460px) {
		height: auto;
		width: 100%;
  	}
`;

const Link = styled.a`
	display:flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
`;

const Contact = styled.div`
	margin-top: 20px;
	text-align: center;
`;

const Legacy = styled.div`
	margin-top: 20px;
	width: 100%;
	text-align: center;
	display:flex; 
	flex-direction: row;
	justify-content: center;
	@media (max-width: 460px) {
    	font-size: 10px;
  	}
`;

const P = styled.p`
	width: 100%
	white-space: pre;
`;

const A = styled.a`
	text-decoration: none;
	color: #B7410E;
`;

export default App;
